import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "./image"

const Header = ({ siteTitle }) => (
  <header>
    <Image path="Logo@2X.png" maxWidth={257} />
    <nav style={{ fontSize: "15px", letterSpacing: "-0.04em" }}>
      <ul>
        <li>
          <Link to="/" className="active">
            BEM-VINDO
          </Link>
        </li>
        <li>
          <Link to="/convidados">CONVIDADOS</Link>
        </li>
        <li>
          <Link to="/curiosidades">CURIOSIDADES</Link>
        </li>
        <li>
          <Link to="/ajuda">COMO AJUDAR</Link>
        </li>
        <li>
          <Link to="/informacoes">INFORMAÇÕES</Link>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
