import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SecondPage = () => (
  <Layout>
    <SEO title="Curiosidades" />
    <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "IMG_3561.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1140) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          `}
          render={data => (
            <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              style={{ width: "100%", marginTop: -38 }}
            />
          )}
        />
        
    <p>O pedido de casamento foi feito no concerto dos U2 em Manchester</p>
    <p>A noiva não chorou durante o pedido, mas o noivo sim</p>
    <p>O anel do pedido servia no mindinho</p>
    <p>
      Quando davamos a noticia do casamento, quase todos acharam que iamos
      anunciar uma gravidez
    </p>
    <p>Uma das nossas datas marcantes é o dia dos finados (1 nov)</p>
    <p>A nossa primeira saída foi à "Catedral"</p>
    <p>A Vespa do noivo teve um furo na primeira volta com a noiva</p>
    <p>A noiva deu a chave da sua casa, ainda antes de a ter</p>
    <p>A nossa lua de mel será na Croácia</p>
  </Layout>
)

export default SecondPage
